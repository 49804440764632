<template>
  <div></div>
</template>

<script>
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: { },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData"))
    }
  },
  created() {
    if(this.userData && this.userData.role) {
      this.$router.push(getHomeRouteForLoggedInUser(this.userData.role))
    }
  }
}
</script>
